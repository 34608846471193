/**
 * Below mocks are used when the page is in editing mode in the builder editor
 * Builder editor does not have the ability to make authenticated requests
 */
export const MOCK_TOKENS = 40;

export const MOCK_RECENT_IMAGES_DATA = [
  {
    "createdAt": "2024-08-22T21:53:57.549Z",
    "previewUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Fpreview.png?t=1724363637549",
    "additionalFields": {
      "userText": "Unfold your wings"
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Ftraced.svg",
    "id": "09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36",
    "artStyle": "generated-image-black-white-graphic-ideogram-text",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "origami birds transforming into real birds mid flight",
    "status": "delivered",
    "deliveredAt": "2024-08-22T22:02:40.570Z",
    "updatedAt": "2024-08-22T22:02:40.656Z",
  },
  {
    "createdAt": "2024-08-21T19:51:19.854Z",
    "previewUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Fe766e952-0259-4fec-a722-d760cf2b57d7%2Fpreview.png?t=1724269879853",
    "additionalFields": {
      "userText": "so green"
    },
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Fe766e952-0259-4fec-a722-d760cf2b57d7%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Fe766e952-0259-4fec-a722-d760cf2b57d7%2Ftraced.svg",
    "id": "e766e952-0259-4fec-a722-d760cf2b57d7",
    "artStyle": "generated-image-logo-graphic-ideogram-text",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "plants",
    "status": "delivered",
    "deliveredAt": "2024-08-21T19:52:13.396Z",
    "updatedAt": "2024-08-21T19:52:13.472Z"
  },
  {
    "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F78cf0dea-18f0-4c77-9a37-68f4bb320593%2Fraw.png",
    "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F78cf0dea-18f0-4c77-9a37-68f4bb320593%2Ftraced.svg",
    "id": "78cf0dea-18f0-4c77-9a37-68f4bb320593",
    "artStyle": "generated-image-black-white-graphic-ideogram-text",
    "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
    "prompt": "origami birds transforming into real birds mid flight",
    "status": "delivered",
    "deliveredAt": "2024-08-22T00:02:19.879Z",
    "updatedAt": "2024-08-22T00:02:19.954Z"
  }, {
    "createdAt": "2024-08-22T00:01:31.715Z",
    "previewUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2Fc491c9fe-7d6d-4cc4-9f17-9c65be2e066f%2Fpreview.png?t=1724284891715",
    "additionalFields": {
      "userText": "Unfold your wings"
    },
  },
];

export const MOCK_IMAGE = {
  "createdAt": "2024-08-22T21:53:57.549Z",
  "previewUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Fpreview.png?t=1724363637549",
  "additionalFields": {
    "userText": "Unfold your wings"
  },
  "imageUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Fraw.png",
  "svgUrl": "https://storage.googleapis.com/glowforge-staging/generated_images%2F09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36%2Ftraced.svg",
  "id": "09d5f9e8-30fc-4d3a-bf88-3a5e2b532d36",
  "artStyle": "generated-image-black-white-graphic-ideogram-text",
  "userId": "8dTzQ7JbDbFAhwoBACmbX6PGJ1bxY",
  "prompt": "origami birds transforming into real birds mid flight",
  "status": "delivered",
  "deliveredAt": "2024-08-22T22:02:40.570Z",
  "updatedAt": "2024-08-22T22:02:40.656Z",
};

export const USER_ACTIONS = {
  REGENERATE: 'regenerate',
  SCRAP: 'scrap',
  DELIVER: 'deliver',
};

export const IMAGE_STATUS = {
  DELETED: 'deleted',
  DELIVERED: 'delivered',
};